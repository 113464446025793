<template>
  <div
    class="flex min-h-screen flex-col bg-white bg-gradient-to-r from-[#FCF1F4] to-[#EDFBF9] font-mulish text-base font-normal text-gray antialiased dark:bg-[#101926] dark:from-transparent dark:to-transparent"
  >
    <SeoKit />

    <the-loading v-show="store.isShowMainLoader" />

    <layout-header :class="{ 'sticky-header': showTopButton }" />

    <div class="-mt-[82px] flex-grow overflow-x-hidden lg:-mt-[106px]">
      <NuxtPage />
    </div>

    <layout-footer />

    <!-- Return to Top -->
    <button
      v-if="showTopButton"
      type="button"
      class="fixed bottom-5 z-10 animate-bounce ltr:right-5 rtl:left-5"
      @click="scrollToTop()"
    >
      <div
        class="group flex h-14 w-14 items-center justify-center rounded-full border border-black/20 bg-black text-white transition duration-500 hover:bg-secondary dark:bg-primary dark:hover:bg-secondary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-6 w-6 transition group-hover:text-black"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          />
        </svg>
      </div>
    </button>
  </div>
</template>

<script setup>
import qs from 'qs'
import { ref, onMounted, toRaw } from 'vue'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
import appSetting from '@/app-setting'

import { useAppStore } from '@/stores/index'
const store = useAppStore()

const query = qs.stringify(
  {
    populate: ['main_image', 'seo'],
    sort: 'ordering',
  },
  {
    encodeValuesOnly: true,
  }
)

const showTopButton = ref(false)

const runtimeConfig = useRuntimeConfig()
const strapiUrl = runtimeConfig.public.strapi.url

await useFetch(`${strapiUrl}/api/categories?${query}`, { key: 'categories' })
  .then((data) => {
    store.$patch((state) => {
      state.categories = data.data
      state.categories = toRaw(state.categories).data
    })
  })
  .catch((err) => console.log({ 'useFetch category#show error': err }))

onMounted(() => {
  // set default settings and animation initalization
  AOS.init({
    once: true,
  })
  appSetting.init()

  window.onscroll = () => {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      showTopButton.value = true
    } else {
      showTopButton.value = false
    }
  }

  store.toggleMainLoader(false)
})

const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}
</script>
